<template>
  <div class="myActivityList">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        class="activityItem"
        v-for="(item, index) in listData"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="item-l"><img :src="item.activityPicture" alt="" /></div>
        <div class="item-r">
          <div class="top">
            <div class="name">
              {{ item.activityName }}
            </div>
            <div
              @click.stop="sign(item)"
              class="btn"
              v-if="
                item.scheduleList[0].pointsSettingId != '' &&
                item.activityStatus == 4 &&
                item.isCheckIn == 0
              "
            >
              签到
            </div>
            <div
              class="btn-ok"
              v-else-if="
                item.scheduleList[0].pointsSettingId != '' &&
                item.activityStatus == 4 &&
                item.isCheckIn == 1
              "
            >
              已签到
            </div>
          </div>
          <p class="p-apply">
            报名开始时间：{{
              item.scheduleList && item.scheduleList[0]
                ? item.scheduleList[0].applyStartDate
                : item.applyStartDate
            }}
          </p>
          <p class="p-activity">
            活动开始时间：{{
              item.scheduleList && item.scheduleList[0]
                ? item.scheduleList[0].startDate
                : item.startDate
            }}
          </p>
        </div>
        <div class="status"></div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { myActivityUrl, getPoints } from "./api.js";
import { getOption } from "@/utils/utils.js";
import { activityStatus } from "./map.js";
export default {
  name: "myEnroll",
  data() {
    return {
      finishedText: "没有更多了",
      activityStatus,
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  mounted() {
    // this.getActivityList();
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    async sign(item) {
      console.log(item);
      let params = {
        communityId: this.communityId,
        roomId: this.roomId,
        userId: this.userId,
        tenantId: this.tenantId,
        operationType: 1,
        settingCode: item.settingCode,
        recordType: 1,
        relationId: `${item.scheduleList[0].activityId};${item.scheduleList[0].id}`,
      };
      const res = await this.$axios.post(`${getPoints}`, params, {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      });
      if (res.code === 200) {
        this.$toast("获取甜蜜值成功");
        this.listData = [];
        this.requestData.curPage = 1;
        this.finished = false;
        this.onLoad();
      } else {
        let msg = res.msg || "获取甜蜜值失败";
        this.$toast(msg);
      }
    },
    toDetail(item) {
      this.$router.push({
        name: "activityWorkOrderDetail",
        query: {
          activityId: item.activityId,
          isApply: 1,
          applyId: item.applyId,
          scheduleId: item.scheduleId,
        },
      });
    },
    getOption(status) {
      if (getOption(status, activityStatus, "key")["value"]) {
        return getOption(status, activityStatus, "key")["value"];
      } else {
        return "";
      }
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios.get(`${myActivityUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.activityPicture) {
              item.activityPicture = this.$handleImg(
                224,
                224,
                item.activityPicture
              );
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    onLoad() {
      this.getActivityList();
    },
  },
};
</script>
<style lang="less" scoped>
.myActivityList {
  padding: 32px;
  background: #f5f5f5;
  min-height: 100vh;
  box-sizing: border-box;
  .activityItem {
    display: flex;
    height: 224px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 32px;
    .item-l {
      width: 224px;
      height: 100%;
      margin-right: 16px;
      img {
        width: 100%;
        border-radius: 16px 0 0 16px;
        height: 100%;
      }
    }
    .item-r {
      flex: 1;
      padding: 24px 32px 0 0;
      .top {
        height: 54px;
        display: flex;
        align-items: center;
        .btn {
          width: 140px;
          height: 54px;
          background: linear-gradient(180deg, #bce7e4 0%, #56a3ad 100%);
          box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
          border-radius: 10px;
          text-align: center;
          line-height: 54px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .btn-ok {
          width: 140px;
          height: 54px;
          background: #ccc;
          box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
          border-radius: 10px;
          text-align: center;
          line-height: 54px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .name {
          width: 270px;
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      p {
        font-size: 24px;
        padding-left: 24px;
        font-weight: 400;
        color: #646668;
        line-height: 36px;
      }
      .p-apply {
        margin: 12px 0 4px;
      }
      .p-apply,
      .p-activity {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background: #1ebb70;
          border-radius: 50%;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .p-activity {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background: #3a69ed;
          border-radius: 50%;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}
</style>
